import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { FaAngleDoubleRight } from "react-icons/fa";
export default class HeaderForPage extends Component {



    render() {
        return (
            <div className={this.props.name === 'Gallery' ? "jumbotron jumbotron-fluid activity-page-header position-relative overlay-bottom margin-bottom  " : 'jumbotron jumbotron-fluid page-header position-relative overlay-bottom margin-bottom'} >
                <div className="container text-center py-5 my-5">

                    <h1 data-aos="zoom-in-down" data-aos-duration="1000" className="text-white display-1  ">{this.props.name}</h1>
                    <div data-aos="zoom-in-down" data-aos-duration="1000" className="d-inline-flex text-white  mb-5">
                        <p className="m-0 text-uppercase"> <Link to={'/'} className="text-white" href="">Home</Link></p>
                        <FaAngleDoubleRight className='mt-1 mx-2' />
                        <p className="m-0 text-uppercase">{this.props.name}</p>
                    </div>


                </div>
            </div>
        )
    }
}
