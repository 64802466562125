import React, { Component } from 'react'
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { MdCall } from "react-icons/md";
export default class TopBar extends Component {
    render() {
        return (
            <div className="container-fluid bg-dark footer-bg">
                <div className="row py-2 px-lg-5">
                    <div className="col-lg-6 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center text-white">
                            <a href='tel:8347763858'>
                                <b><small className='text-white'><MdCall className='me-1' />+91 834 776 3858</small></b>
                            </a>

                        </div>
                    </div>
                    <div className="col-lg-6 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center">
                            <a className="text-white px-2" target='_blank' href="https://www.facebook.com/Decode-Softtech-108716078502384">
                                <FaFacebookF />
                            </a>
                            <a className="text-white px-2" target='_blank' href="">
                                <FaTwitter />
                            </a>
                            <a className="text-white px-2" target='_blank' href="https://www.linkedin.com/company/81620839/admin/">
                                <FaLinkedinIn />
                            </a>
                            <a className="text-white px-2" target='_blank' href="https://www.instagram.com/decodesofttech/">
                                <FaInstagram />
                            </a>

                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
