import React, { Component } from 'react'
import { FaAngleRight, FaFacebookF, FaInstagram, FaLinkedinIn, FaMapMarkerAlt, FaPhoneAlt, FaTwitter } from 'react-icons/fa'
import { MdEmail } from 'react-icons/md'
import { Link } from 'react-router-dom'
const whiteLogo = 'https://firebasestorage.googleapis.com/v0/b/hey1-portfolio.appspot.com/o/decode%2Fwebsite%2Fimages%2Fwhite%20logo.png?alt=media&token=08482253-bb78-4cd4-8c43-2abede93190c&_gl=1*9fwdmm*_ga*NDYyMDY3ODYuMTY3ODk0MDcxNA..*_ga_CW55HF8NVT*MTY4NTY5NDU3Ny4yMS4xLjE2ODU2OTQ2NzAuMC4wLjA.'

export default class Footer extends Component {
    render() {
        return (
            <>

                <div className="container-fluid position-relative overlay-top bg-dark footer-bg text-white-50 py-5" style={{ marginTop: '90px' }}>
                    <div className="container mt-5 pt-5">
                        <div className="row">
                            <div className="col-md-6 mb-5">
                                <a href="/" className="navbar-brand">
                                    <img className="m-0 logo" src={whiteLogo} alt="Decode Softtech" />
                                </a>
                                {/* <!-- <p className="m-0">Accusam nonumy clita sed rebum kasd eirmod elitr. Ipsum ea lorem at et diam est,
                                        tempor rebum ipsum sit ea tempor stet et consetetur dolores. Justo stet diam ipsum lorem vero
                                        clita diam</p> --> */}
                            </div>
                            {/* <!-- <div className="col-md-6 mb-5">
                                    <h3 className="text-white mb-4">Newsletter</h3>
                                    <div className="w-100">
                                        <div className="input-group">
                                            <input type="text" className="form-control border-light" style="padding: 30px;"
                                                placeholder="Your Email Address">
                                                <div className="input-group-append">
                                                    <button className="btn btn-primary primary-btn px-4">Sign Up</button>
                                                </div>
                                        </div>
                                    </div>
                                </div> --> */}
                        </div>
                        <div className="row">
                            <div className="col-md-4 mb-5">
                                <h3 className="text-white mb-4">Get In Touch</h3>
                                <p> <a className="text-white-50" href="https://goo.gl/maps/tY68pnXuMjLQ1Jc19" target="_blank"><FaMapMarkerAlt className='me-2' />
                                    304, Dhara Arcade, Mahadev Chowk, Mota
                                    Varachha, Surat</a></p>
                                <p>
                                    <a className="text-white-50" href="tel:8347763858"> <FaPhoneAlt className='me-2' />+91 834 776
                                        3858</a>
                                </p>
                                <p>
                                    <a className="text-white-50" href="mailto:hr@decodesofttech.com">
                                        <MdEmail className='me-2' />
                                        hr@decodesofttech.com </a>
                                </p>
                                <div className="d-flex justify-content-start mt-4">
                                    <a className="text-white me-4" target='_blank' href="#"> <FaTwitter /> </a>
                                    <a className="text-white me-4" target='_blank' href="https://www.facebook.com/Decode-Softtech-108716078502384">
                                        <FaFacebookF />
                                    </a>
                                    <a className="text-white me-4" target='_blank' href="https://www.linkedin.com/company/81620839/admin/">
                                        <FaLinkedinIn /> </a>
                                    <a className="text-white" target='_blank' href="https://www.instagram.com/decodesofttech/">
                                        <FaInstagram />
                                    </a>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <h3 className="text-white mb-4">We Provide</h3>
                                <div className="d-flex flex-column justify-content-start">
                                    <a className="text-white-50 mb-2" href="/details/1"><FaAngleRight className='me-2' />Web Design</a>
                                    <a className="text-white-50 mb-2" href="/details/2"><FaAngleRight className='me-2' />Frontend
                                        Development</a>
                                    <a className="text-white-50 mb-2" href="/details/3"><FaAngleRight className='me-2' />Backend
                                        Development</a>
                                    <a className="text-white-50 mb-2" href="/details/4"><FaAngleRight className='me-2' />Fullstack
                                        Development</a>
                                    <a className="text-white-50 mb-2" href="/details/6"><FaAngleRight className='me-2' />Firebase</a>
                                    <a className="text-white-50 mb-2" href="/details/5"><FaAngleRight className='me-2' />360 & 3D
                                        Website</a>
                                    <a className="text-white-50 mb-2" href="/details/2"><FaAngleRight className='me-2' />React Js</a>
                                    <a className="text-white-50 " href="/details/3 "><FaAngleRight className='me-2' />Node Js</a>
                                </div>
                            </div>
                            <div className="col-md-4 mb-5">
                                <h3 className="text-white mb-4">Quick Links</h3>
                                <div className="d-flex flex-column justify-content-start">
                                    <Link className="text-white-50 mb-2" to={'/'}><FaAngleRight className='me-2' />Home</Link>
                                    {/* <a className="text-white-50 mb-2" href="/"><FaAngleRight className='me-2' />Home</a> */}
                                    {/* <a className="text-white-50 mb-2" href="/about">
                                        <FaAngleRight className='me-2' />About Us</a> */}
                                    <Link className="text-white-50 mb-2" to={'/about'}> <FaAngleRight className='me-2' />About Us</Link>
                                    {/* <a className="text-white-50 mb-2" href="/courses">
                                        <FaAngleRight className='me-2' />Courses</a> */}
                                    <Link className="text-white-50 mb-2" to={'/courses'}><FaAngleRight className='me-2' />Courses</Link>
                                    {/* <a className="text-white-50 mb-2" href="#"><FaAngleRight className='me-2' />Terms &
                                        Condition</a> */}
                                    <Link className="text-white-50 mb-2" to={'/terms'}><FaAngleRight className='me-2' />Terms &
                                        Condition</Link>
                                    <Link className="text-white-50 mb-2" to={'/placement-partners'}><FaAngleRight className='me-2' />Placement Partners</Link>
                                    {/* <a className="text-white-50" href="/contact">
                                        <FaAngleRight className='me-2' />Contact Us</a> */}
                                    <Link className="text-white-50 mb-2" to={'/gallery'}><FaAngleRight className='me-2' />Our Activity</Link>
                                    <Link className="text-white-50 mb-2" to={'/career'}><FaAngleRight className='me-2' />Career</Link>
                                    <Link className="text-white-50 mb-2" to={'/contact'}><FaAngleRight className='me-2' />Contact Us</Link>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid bg-dark footer-bg text-white-50 border-top py-4 border-color">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 text-center text-md-left mb-3 mb-md-0">
                                <p className="m-0">© Copyright 2023. <a className="text-white" href="https://decodesofttech.com">Decode
                                    Softtech</a>. All Rights
                                    Reserved.
                                </p>
                            </div>
                            {/* <!-- <div className="col-md-6 text-center text-md-right">
                                    <p className="m-0">Designed by <a className="text-white" href="https://htmlcodex.com">HTML Codex</a>
                                    </p>
                                </div> --> */}
                        </div>
                    </div>
                </div>

            </>
        )
    }
}
